import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Box } from "@mui/material";

const TextEditior = ({ onchange, value, label, placeholder, isError = false, error = ''}) => {
   

  return (
    <Box style={{padding:10}}>
    <p style={{ padding: 0, margin: 0, marginBottom:10, color: "#4E657C", fontSize: 19, fontWeight: 700 }}>{label}</p>

      <ReactQuill
        className="text-editor"
        placeholder={placeholder}
        value={value}
        onChange={onchange}
        
      />
      {isError ? <p className="input-error-text">{error}</p> : null}
    </Box>
  );
};

export default TextEditior;
