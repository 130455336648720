import React from "react";
import { Box } from "@mui/material";


const TextInputComponent = ({isError = false, error = '', backgroundColor = '#f2f6f8', placeholder = '', type = 'text', value = '', onchange = () => { }, name = '', label = '', readOnly = false, min=0, borderStyle="none"}) => {
    return (<Box style={{padding:10}}>
        <p style={{ padding: 0, margin: 0, marginBottom:10, color: "#4E657C", fontSize: 19, fontWeight: 700 }}>{label}</p>
        <input  maxLength="255" className="form-control" style={{backgroundColor:backgroundColor, borderStyle:borderStyle, borderWidth:1, borderColor:'#bdbebf'}} value={value} type={type} placeholder={placeholder} onChange={onchange} readOnly={readOnly} name={name} min={min} />
        {isError ? <p className="input-error-text">{error}</p> : null}
    </Box>);
}

export default TextInputComponent;