import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { USER_ROLE } from "../../../core/Constant";
import { useEffect, useState } from "react";
import { Actions } from "../../../core/modules/Actions";
import { connect } from "react-redux";
import { NotificationAlert } from "../../molecule";

const StudentRoutes = ({getcourseProgress,courseprogress,getNotifications,notifications}) => {
    const [intialcourse,setInitialCourse] = useState([]);
    const [unreadnotify,setUnread] = useState()
    const [read,isRead] = useState(true);
    const navigate = useNavigate();
    useEffect(()=>{
        getcourseProgress()
    },[])

    useEffect(()=>{
      let user = parseInt(localStorage.getItem('userId'));
      getNotifications(user)
    },[])

    useEffect(()=>{
      const unread = notifications.filter(notification => notification.is_read === 0);
      setUnread(unread)
    },[notifications])

    useEffect(()=>{
       setInitialCourse(courseprogress[0])
       localStorage.setItem('firstcourse', intialcourse?.course_id)
    },[courseprogress,intialcourse])

    const handleClose = () =>{
      isRead(false)
      navigate("/StudentNotification");
      
    }





    let user = parseInt(localStorage.getItem('userType'));
    return (<>
    {user == USER_ROLE.student ? <Outlet/>: <Navigate to="*"/>}
    {
      unreadnotify?.length > 0 ? <>
         <NotificationAlert handleClose={handleClose} isread={read} data={{title:'Notification',message: unreadnotify.length > 1 ? `You have  ${unreadnotify.length}  Unread Notifications` : 'You have Unread Notification' }}/>
      </> : <></> 
    }
    </>);
}

export default connect(
        (state) => ({
          courseprogress: state.home.get("courseprogress"),
          notifications:state.notification.get("notifications"),
        }),
        {
          getcourseProgress: Actions.home.getcourseProgress,
          getNotifications:Actions.notification.getNotifications

        }
)(StudentRoutes);