import React, { useEffect, useState } from "react";
import { Box, DialogActions, DialogContent } from "@mui/material";
import TextInputComponent from "../../../atom/Inputs/TextInput";
import TextButtonComponet from "../../../atom/Buttons/TextButton";
import TextAreaComponent from "../../../atom/Inputs/TextArea";
import { IconButtonComponent, TextEditior } from "../../../atom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Actions } from "../../../../core/modules/Actions";


const AdminNewsletterEdit = ({ onClose, newsletterDetails }) => {
    const [newsletterslug, setNewsletterslug] = useState();
    const [newslettercontent, setNewslettercontent] = useState();
    const [newslettertitle, setNewslettertitle] = useState();
    const [contentValid, setIsContentValid] = useState(false);
    const [contentIsError, setIsContentError] = useState(false);
    const [newsletterId, setNewsLetterId] = useState()
    const dispatch = useDispatch();

    useEffect(() => {
        setNewsletterslug(newsletterDetails?.slug);
        setNewslettertitle(newsletterDetails?.title);
        setNewslettercontent(newsletterDetails?.content)
        setNewsLetterId(newsletterDetails?.id)
    }, [newsletterDetails]);

    const handleSubmit = async (event) => {
        dispatch(Actions.newsLetter.updateNewsletterContent({ id: newsletterId, content: newslettercontent, title: newslettertitle }))
        onClose();
    };

    const onChangeContent = (text) => {
        setIsContentError(false);
        setIsContentValid(text.length > 0);
        setNewslettercontent(text);
    }
    return (
        <>
            <DialogContent>
                <Box>
                    <TextInputComponent
                        label={"NewsLetter Title"}
                        name="name"
                        value={newslettertitle}
                        onchange={(e) => setNewslettertitle(e.target.value)}
                        placeholder="First Name"
                        readOnly={false}
                    />
                </Box>
                <Box>
                    <TextEditior
                        height={500}
                        value={newslettercontent}
                        isError={contentIsError}
                        error="Please add Content"
                        label={"Content"}
                        placeholder={"Add Content"}
                        onchange={onChangeContent}
                    />
                </Box>
            </DialogContent>

            <DialogActions>
                <Box sx={{ display: "flex", width: "50%", mr: 2.2, mb: 2, ml: 2.2 }}>
                    <Box sx={{ display: "flex", gap: 0.5, flexGrow: 1 }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <TextButtonComponet text={"Update"} classStyle="btn btn-primary" onButtonClick={handleSubmit} />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            <TextButtonComponet text={"Cancel"} classStyle="btn btn-secondary" onButtonClick={() => onClose()} />
                        </Box>
                    </Box>

                </Box>
            </DialogActions>

        </>
    );
}

export default AdminNewsletterEdit;
