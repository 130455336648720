import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { connect } from "react-redux";
import { Actions } from "../../../../core/modules/Actions";
import { useNavigate } from "react-router-dom";
import HeadingComponent from "../../../../component/atom/Headings/Heading";
import { NotificationCard, NotificationAlert } from "../../../../component/molecule";
import _ from 'lodash'

const StudentAlertScreen = ({ notifications, getNotifications, currentUser, readNotification, getNotifyId }) => {
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false);
    const [notifyId, setNotifyId] = useState(null);

    useEffect(() => {
        getNotifications(currentUser?.id);
    }, [currentUser]);

    useEffect(() => {
        setList(notifications);
    }, [notifications]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            _.forEach(_.filter(notifications, { 'is_read': 0 }), notification => {
                readNotification(notification.id); 
            });
            setList(notifications.map(notification => ({
                ...notification,
                is_read: 1 
            })));
        }, 3000); 

        return () => clearTimeout(timeoutId);
    
    }, [notifications, readNotification]);
    
    const handleCardClick = (item) => {
        setOpen(true);
        setData(item);
        setNotifyId(item?.id);
    };

    const handleClose = () => {
        if (data?.is_read === 0) {
            readNotification(notifyId);
            // Update list locally when a notification is manually marked as read
            setList(list.map(notification => 
                notification.id === notifyId ? { ...notification, is_read: 1 } : notification
            ));
        }
        setOpen(false);
    };

    return (
        <Box className="main-screen-container">
            <Box mb={4} mt={3}>
                <HeadingComponent text={"Notification"} fontWeight={600} size={26} fontFamily={"Montserrat"} />
                <HeadingComponent text={"Find new updates and Notifications"} color={'#4a6375'} fontWeight={300} size={15} fontFamily={"Montserrat"} />
            </Box>
            {list.length > 0 ? (
                list.map((item, index) => (
                    <Grid container key={`std-announcement_${index}`} >
                        <NotificationCard  handleCardClick={() => handleCardClick(item)} item={item} />
                    </Grid>
                ))
            ) : (
                <p>No Notifications found</p>
            )}
            <NotificationAlert
                isRead={open}
                data={data}
                handleClose={()=>handleClose()}
            />
        </Box>
    );
};

export default connect(
    state => ({
        notifications: state.notification.get('notifications'),
        currentUser: state.profile.get("currentUser")
    }),
    {
        getNotifications: Actions.notification.getNotifications,
        readNotification: Actions.notification.readNotification,
        getNotifyId: Actions.notification.getNotifyId // Assuming you have an action for getNotifyId
    }
)(StudentAlertScreen);
